import React from "react";
// import { useNavigate, Link } from "react-router-dom";
// import axios from "../axios";

export const AccContext = React.createContext();

export const AccProvider = (props) => {
  const [showlogin, setShowlogin] = React.useState("");
  const [showSca, setShowSca] = React.useState("");
  const [showBlown, setShowBlown] = React.useState(false)
  const [showInactive, setShowInactive] = React.useState(false)
  const [showtype, setShowType] = React.useState("Select Account");
  const [showsize, setShowSize] = React.useState(3000);
  const [accdeeds, setAccdeeds] = React.useState([]);
  const [banked, setBanked] = React.useState(null)
  const [loading, setLoading] = React.useState(false)
  const [globalRefresh, setGlobalRefresh] = React.useState(false)

  return (
    <AccContext.Provider
      value={{
        values: [showlogin, setShowlogin],
        values2: [showtype, setShowType],
        values3: [showsize, setShowSize],
        values4: [accdeeds, setAccdeeds],
        values5: [showSca, setShowSca],
        values6: [banked, setBanked],
        values7: [showBlown, setShowBlown],
        values8: [loading, setLoading],
        values9:[globalRefresh, setGlobalRefresh],
        values10:[showInactive, setShowInactive]
      }}
    >
      {props.children}
    </AccContext.Provider>
  );
};
