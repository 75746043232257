/* eslint-disable no-unused-vars */
import React, { useState } from "react";
import { Box, Button } from "@mui/material";
import LandingCarousel from "../Componets/LandingCarousel";
import LandingTable from "../Componets/LandingTable";
import axios from "../axios";
import { Helmet } from "react-helmet";
import { Link, useNavigate, useSearchParams } from "react-router-dom";
import { AccContext } from "../Context/OtherContext";
import NotifModal from "../Componets/NotifModal";
// import Cookies from 'js-cookie';
function Home() {
  const token = sessionStorage.getItem("access_token");
  const [info, setInfo] = React.useState({});
  const refresh_token = sessionStorage.getItem("access_token");
  const [accounts, setAccounts] = useState(false);
  const [available, setAvailable] = React.useState(false);
  const { values9 } = React.useContext(AccContext);
  const [globalRefresh, setGlobalRefresh] = values9;
  const [open, setOpen] = React.useState(false);

  React.useEffect(() => {
    const intervalId = setInterval(
      axios
        .get("/notifications/")
        .then((res) => {
          console.log(res);
          if (res.data.length > 0) {
            setAvailable(true);
          } else {
            setAvailable(false);
          }
        })
        .catch((err) => {
          console.log(err);
        }),
      120000
    );

    // Clear the interval when the component unmounts to prevent memory leaks
    return () => {
      clearInterval(intervalId);
    };
  }, [globalRefresh]);

  React.useEffect(() => {
    axios
      .get(`/auth/user/`)
      .then((res) => {
        console.log(res.data.is_staff);
        setInfo(res.data.is_staff);
      })
      .catch((err) => {
        console.log(err);
      });
    // sessionStorage.setItem("access_token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoiYWNjZXNzIiwiZXhwIjoxNzExODA2ODY5LCJpYXQiOjE3MDkyMTQ4NjksImp0aSI6ImQyOGU1OTQ5Y2QxYjQ4YmM5Zjc5NGMxNjNlODA5YjZmIiwidXNlcl9pZCI6Mzh9.3e9i1SRSHvEmET2R8-3vGSvw_byKHTsfPIK48Z_mnyw")
    // sessionStorage.setItem("refresh_token", "eyJ0eXAiOiJKV1QiLCJhbGciOiJIUzI1NiJ9.eyJ0b2tlbl90eXBlIjoicmVmcmVzaCIsImV4cCI6MTcxMjY3MDg2OSwiaWF0IjoxNzA5MjE0ODY5LCJqdGkiOiJkZDZiYzYzMGM3YWQ0MDM2OGRkNTBjYjA1Yjk3MTBhZiIsInVzZXJfaWQiOjM4fQ.SjrbfNNy2LUe1kVU--m1GfR3Z6F8MkqonwnhFkoluDA")
  }, []);

  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  var latoken = searchParams.get("token");
  // const number = searchParams.get("number")
  React.useEffect(() => {
    // Cookies.set("access_token", null);
    // Cookies.set("refresh_token", null);
    var token = searchParams.get("token");
    var refresh = searchParams.get("refresh_token");
    if (token) {
      // Cookies.set("access_token", token)
      sessionStorage.setItem("access_token", token);
    }

    if (refresh) {
      // Cookies.set("refresh_token", refresh)
      sessionStorage.setItem("refresh_token", refresh);
    }

    if (token !== null) {
      //console.log("token is not null");
      return navigate("/");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  React.useEffect(() => {
    axios
      .get("/getaccdetails/")
      .then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          setAccounts(true);
        } else {
          setAccounts(false);
        }
        // window.location.reload()
      })
      .catch((err) => {
        console.log(err);
        setAccounts(false);
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    const hasReloaded = sessionStorage.getItem("hasReloaded");
    if (!hasReloaded) {
      sessionStorage.setItem("hasReloaded", "true");
      window.location.reload();
    }
  }, [latoken]);

  React.useEffect(() => {
    const handleNavigation = () => {
      // Remove the item from sessionStorage
      sessionStorage.removeItem("hasReloaded");
    };

    // Listen for when the user is about to leave the page
    window.addEventListener("beforeunload", handleNavigation);

    // Listen for history changes (like Back/Forward button navigation)
    window.addEventListener("popstate", handleNavigation);

    return () => {
      // Clean up the event listeners
      window.removeEventListener("beforeunload", handleNavigation);
      window.removeEventListener("popstate", handleNavigation);
    };
  }, []);

  return (
    <>
      <Helmet>
        <title>Home - FT9ja</title>
      </Helmet>
      <Box className="relative">
        {/* {loading && <div className="absolute right-0 top-0 bottom-0 left-0 bg-black/50 z-10 flex items-start justify-center">
          <div className="bg-white w-[50%] h-[30%] mt-[10rem] rounded-lg">Please Wait...</div>
        </div>} */}
        <Box className="Landing">
          <Box
            sx={{
              paddingY: 2,
              paddingLeft: 1,
            }}
          >
            {/* {token ? (
              <Button
                className="Btn"
                sx={{
                  position: "absolute",
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href="/dashboards"
              >
                Dashboard
              </Button>
            ) : (
              <Button
                className="Btn"
                sx={{
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href="/login"
              >
                Login
              </Button>
            )} */}
            {!token && (
              <Button
                className="Btn"
                sx={{
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href="/login"
              >
                Login
              </Button>
            )}
            {accounts && (
              <Link to="/dashboards">
                <Button
                  className="Btn"
                  sx={{
                    position: "absolute",
                    float: "right",
                    fontSize: 18,
                    textTransform: "none",
                    background: "#359602",
                    " &:hover": {
                      backgroundColor: "#359602",
                    },
                  }}
                  variant="contained"
                  // href="/dashboards"
                >
                  Dashboard
                </Button>
              </Link>
            )}
            {!accounts && (
              <div className="flex items-center justify-end w-full">
                <div
                  onClick={() => setOpen(!open)}
                  className={`Tabz1 cursor-pointer relative w-[100] mr-5`}
                >
                  {available && (
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="7"
                      height="7"
                      viewBox="0 0 4 4"
                      fill="none"
                      className="absolute top-[0.6rem] right-[1rem]"
                    >
                      <circle cx="2" cy="2" r="2" fill="#FF0000" />
                    </svg>
                  )}
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="24"
                    height="24"
                    viewBox="0 0 24 24"
                    fill="none"
                  >
                    <path
                      d="M19.3399 14.49L18.3399 12.83C18.1299 12.46 17.9399 11.76 17.9399 11.35V8.82C17.9399 6.47 16.5599 4.44 14.5699 3.49C14.0499 2.57 13.0899 2 11.9899 2C10.8999 2 9.91994 2.59 9.39994 3.52C7.44994 4.49 6.09994 6.5 6.09994 8.82V11.35C6.09994 11.76 5.90994 12.46 5.69994 12.82L4.68994 14.49C4.28994 15.16 4.19994 15.9 4.44994 16.58C4.68994 17.25 5.25994 17.77 5.99994 18.02C7.93994 18.68 9.97994 19 12.0199 19C14.0599 19 16.0999 18.68 18.0399 18.03C18.7399 17.8 19.2799 17.27 19.5399 16.58C19.7999 15.89 19.7299 15.13 19.3399 14.49Z"
                      fill="black"
                      fill-opacity="0.8"
                    />
                    <path
                      d="M14.8297 20.01C14.4097 21.17 13.2997 22 11.9997 22C11.2097 22 10.4297 21.68 9.87969 21.11C9.55969 20.81 9.31969 20.41 9.17969 20C9.30969 20.02 9.43969 20.03 9.57969 20.05C9.80969 20.08 10.0497 20.11 10.2897 20.13C10.8597 20.18 11.4397 20.21 12.0197 20.21C12.5897 20.21 13.1597 20.18 13.7197 20.13C13.9297 20.11 14.1397 20.1 14.3397 20.07C14.4997 20.05 14.6597 20.03 14.8297 20.01Z"
                      fill="black"
                      fill-opacity="0.8"
                    />
                  </svg>
                </div>
              </div>
            )}
            {info === true ? (
              <Button
                className="Btn"
                sx={{
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href={`https://www.admin.ft9ja.com/?token=${token}&refresh_token=${refresh_token}`}
              >
                Admin
              </Button>
            ) : null}
          </Box>
          <Box
            sx={{
              fontSize: 15,
            }}
            className="Landingheader"
          >
            <h1>Trade up to $50,000</h1>
            <p className="landp">
              Get the Account Size required to become a successful Trader
            </p>
            <Button
              className="landbtn"
              sx={{
                fontSize: 20,
                textTransform: "none",
                background: "#359602",
                " &:hover": {
                  backgroundColor: "#359602",
                },
              }}
              variant="contained"
              href="#table"
            >
              Let’s start
            </Button>
          </Box>
          <br />
          <LandingCarousel />
          <br />
          <Box className="Landingheader">
            {/* <h6>
              Thousands of Traders love trading with FT9ja Prop Firm. Here are
              some of their stories.
            </h6> */}
            <div id="table" className="">
              <LandingTable />
            </div>
          </Box>
        </Box>
      </Box>
      <NotifModal isOpen={open} setIsOpen={setOpen} />
    </>
  );
}

export default Home;
