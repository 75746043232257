import React, { useEffect } from "react";
import axios from "../axios";
import { AccContext } from "../Context/OtherContext";
import ReactPaginate from "react-paginate";

const convertDate = (dateString) => {
  const dateObject = new Date(dateString);
  const readableDate = dateObject.toLocaleString();
  return readableDate;
};

function TradingHistory() {
  const { values } = React.useContext(AccContext);
  const [showlogin] = values;
  const [tableData, setTableData] = React.useState([]);
  const [currentItems, setCurrentItems] = React.useState(null);
  const [pageCount, setPageCount] = React.useState(0);
  const [itemOffset, setItemOffset] = React.useState(0);
  const itemsPerPage = 5;
  const [currentPage, setCurrentPage] = React.useState(1);
  const [openFilter, setOpenFilter] = React.useState(false);
  const [filterValue, setFilterValue] = React.useState(null);
  useEffect(() => {
    axios
      .get(`report/${showlogin}/`)
      .then((res) => {
        console.log(res);
        setTableData(res.data);
      })
      .catch((err) => {
        console.log(err);
      });
  }, [showlogin]);

  useEffect(() => {
    const endOffset = itemOffset + itemsPerPage;
    console.log(`Loading items from ${itemOffset} to ${endOffset}`);
    if (filterValue === null) {
      setCurrentItems(tableData.slice(itemOffset, endOffset));
      setPageCount(Math.ceil(tableData.length / itemsPerPage));
    } else {
      setCurrentItems(
        tableData
          .filter((e) => e.less_than_5_minutes === filterValue)
          .slice(itemOffset, endOffset)
      );
      setPageCount(
        Math.ceil(
          tableData.filter((e) => e.less_than_5_minutes === filterValue)
            .length / itemsPerPage
        )
      );
    }
  }, [filterValue, itemOffset, tableData]);

  // Invoke when user click to request another page.
  const handlePageClick = (event) => {
    const newOffset = (event.selected * itemsPerPage) % tableData.length;
    console.log(
      `User requested page number ${event.selected}, which is offset ${newOffset}`
    );
    setItemOffset(newOffset);
    setCurrentPage(event.selected + 1);
  };

  return (
    <div onClick={() => setOpenFilter(false)}>
      <div>
        <h1>Trading History</h1>
        <div
          className="relative flex items-center justify-end pr-5"
          onClick={(e) => {
            e.stopPropagation();
            setOpenFilter(!openFilter);
          }}
        >
          {filterValue !== null && (
            <p
              className="text-[0.9rem] my-0 mr-2 cursor-pointer underline text-[#359602]"
              onClick={(e) => {
                e.stopPropagation();
                setOpenFilter(false);
                setFilterValue(null);
              }}
            >
              Clear filter
            </p>
          )}
          <div
            className="bg-gray-200 p-1 rounded-lg w-[120px] font-semibold cursor-pointer text-[0.8rem]"
            style={{ border: "1px solid #359602" }}
          >
            {filterValue === null
              ? "Filter by 5mins rule"
              : filterValue === true
              ? "Less than"
              : "Greater than"}
          </div>
          {openFilter && (
            <div
              className="h-[60px] w-[130px] shadow-lg absolute -bottom-[4rem] rounded-lg"
              style={{ border: "1px solid #359602" }}
            >
              <button
                onClick={() => setFilterValue(true)}
                className="h-[49%] bg-gray-100 w-[100%] border-none rounded-t-lg text-[0.9rem] hover:bg-[#359602]"
              >
                Less than
              </button>
              <hr className="w-[100%] h-[1px] border m-0" />
              <button
                onClick={() => setFilterValue(false)}
                className="h-[49%] bg-gray-100 w-[100%] border-none rounded-b-lg text-[0.9rem] hover:bg-[#359602]"
              >
                Greater than
              </button>
            </div>
          )}
        </div>
        {tableData.length > 0 ? (
          <div className="px-[1rem]">
            <table className="w-[100%] border-collapse border-black bg-white rounded-[1.25rem] ">
              <thead>
                <tr>
                  <td
                    className={`text-center whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}
                  >
                    Position ID
                  </td>
                  <td
                    className={`text-center whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}
                  >
                    Symbol
                  </td>
                  <td
                    className={`text-center whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}
                  >
                    Volume
                  </td>
                  <td
                    className={`text-center whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}
                  >
                    Open Price
                  </td>
                  <td
                    className={`text-center whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}
                  >
                    Close Price
                  </td>
                  <td
                    className={`text-center whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}
                  >
                    Open Time
                  </td>
                  <td
                    className={`text-center whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}
                  >
                    Close Time
                  </td>
                  <td
                    className={`text-center whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}
                  >
                    Open duration(mins)
                  </td>
                  <td
                    className={`text-center whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}
                  >
                    Comment
                  </td>
                  <td
                    className={`text-center whitespace-nowrap py-[1rem] text-[#16161699] font-poppins text-[0.875rem]`}
                  >
                    Less than 5 Mins
                  </td>
                </tr>
              </thead>
              <tbody>
                {currentItems?.map((da, index) => (
                  <tr className={index % 2 === 0 && "bg-[#F1F1F1]"} key={index}>
                    <td className="text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                      {da.position_id}
                    </td>
                    <td className="text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                      {da.symbol}
                    </td>
                    <td className="text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                      {da.volume_initial}
                    </td>
                    <td className="text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                      {da.price_open}
                    </td>
                    <td className="text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                      {da.price_current}
                    </td>
                    <td className="text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                      {convertDate(da.time_open)}
                    </td>
                    <td className="text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                      {convertDate(da.time_close)}
                    </td>
                    <td className="text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                      {da.time_difference}
                    </td>
                    <td className="text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                      {da.comment}
                    </td>
                    <td className="text-center whitespace-nowrap py-[1rem] lg:px-[0.5rem] px-[1rem] font-poppins text-[0.875rem] text-[#161616]">
                      {da.less_than_5_minutes === false ? "False" : "True"}
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        ) : (
          <div>
            <p>There is no trading history for this account.</p>
          </div>
        )}
      </div>
      {tableData?.length > 0 && (
        <div className="mt-[1.5rem] flex lg:flex-row flex-col gap-[1rem] items-center justify-between px-[1rem]">
          <p className="font-poppins text-[0.875rem] text-[#16161699]">
            Page {currentPage} of {pageCount} ({tableData.length} items)
          </p>
          <ReactPaginate
            breakLabel="..."
            nextLabel={
              <svg
                className="w-[1.5rem] h-[1.5rem]"
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
              >
                <path
                  d="M6.43613 10.1566C6.39102 10.2044 6.35576 10.2605 6.33235 10.3219C6.30894 10.3832 6.29785 10.4486 6.29971 10.5143C6.30157 10.5799 6.31634 10.6446 6.34318 10.7045C6.37001 10.7644 6.4084 10.8185 6.45613 10.8636C6.50387 10.9087 6.56002 10.944 6.62139 10.9674C6.68275 10.9908 6.74812 11.0019 6.81378 11C6.87943 10.9982 6.94407 10.9834 7.00401 10.9566C7.06395 10.9297 7.11802 10.8914 7.16313 10.8436L11.4131 6.34362C11.5009 6.25079 11.5498 6.12788 11.5498 6.00012C11.5498 5.87236 11.5009 5.74945 11.4131 5.65662L7.16313 1.15612C7.11832 1.10734 7.06426 1.06795 7.0041 1.04024C6.94394 1.01252 6.87887 0.997042 6.81267 0.99469C6.74647 0.992338 6.68047 1.00316 6.61849 1.02654C6.55651 1.04991 6.49979 1.08536 6.45163 1.13084C6.40347 1.17632 6.36483 1.23091 6.33795 1.29145C6.31106 1.35199 6.29648 1.41726 6.29504 1.48349C6.29359 1.54971 6.30532 1.61556 6.32955 1.67721C6.35377 1.73886 6.39 1.79509 6.43613 1.84262L10.3621 6.00012L6.43613 10.1566Z"
                  fill="#161616"
                />
              </svg>
            }
            onPageChange={handlePageClick}
            pageRangeDisplayed={5}
            pageCount={pageCount}
            previousLabel={
              <svg
                className="w-[1.5rem] h-[1.5rem]"
                xmlns="http://www.w3.org/2000/svg"
                width="13"
                height="12"
                viewBox="0 0 13 12"
                fill="none"
              >
                <path
                  d="M7.16348 1.84338C7.20859 1.79564 7.24385 1.73949 7.26726 1.67813C7.29067 1.61676 7.30176 1.55139 7.2999 1.48574C7.29804 1.42009 7.28327 1.35544 7.25643 1.2955C7.22959 1.23556 7.19121 1.18149 7.14348 1.13638C7.09574 1.09127 7.03959 1.05601 6.97822 1.0326C6.91686 1.00919 6.85148 0.998099 6.78583 0.999956C6.72018 1.00181 6.65554 1.01658 6.5956 1.04342C6.53565 1.07026 6.48159 1.10864 6.43648 1.15638L2.18648 5.65638C2.09871 5.74921 2.0498 5.87212 2.0498 5.99988C2.0498 6.12764 2.09871 6.25055 2.18648 6.34338L6.43648 10.8439C6.48129 10.8927 6.53534 10.9321 6.59551 10.9598C6.65567 10.9875 6.72074 11.003 6.78694 11.0053C6.85314 11.0077 6.91914 10.9968 6.98112 10.9735C7.0431 10.9501 7.09981 10.9146 7.14797 10.8692C7.19613 10.8237 7.23478 10.7691 7.26166 10.7086C7.28855 10.648 7.30313 10.5827 7.30457 10.5165C7.30602 10.4503 7.29429 10.3844 7.27006 10.3228C7.24584 10.2611 7.20961 10.2049 7.16348 10.1574L3.23748 5.99988L7.16348 1.84338Z"
                  fill="#161616"
                />
              </svg>
            }
            renderOnZeroPageCount={null}
            className="flex font-poppins list-none cursor-pointer items-center text-[0.875rem] text-[#161616] gap-[1rem]"
            containerClassName="pagination"
            pageLinkClassName="page-num"
            activeClassName="bg-green-500 text-white cursor-pointer h-[1.25rem] w-[1.25rem] flex items-center justify-center rounded-[0.125rem]"
          />
        </div>
      )}
    </div>
  );
}

export default TradingHistory;
