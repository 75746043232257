import { Button, Typography, Modal, Box } from "@mui/material";
import React, { useState } from "react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import axios from "../axios";
import { AccContext } from "../Context/OtherContext";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const mainContainer = {
  justifyContent: "center",
  alignItems: "center",
};

function SecondChance() {
  const [open, setOpen] = useState(false);
  const { values } = React.useContext(AccContext);
  const [showlogin] = values;
  //--Toast messages--//
  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: "bottom-center",
    });
  };

  const getSecondChance = () => {
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    axios
      .post("/request2nd/", { number: showlogin, device_type: deviceType })
      .then((res) => {
        // console.log(res);
        showRequestSuccess("Your request has been sent!");
        setOpen(false);
      })
      .catch((err) => {
        showRequestFailed(
          "You have already made a request for a second chance account, please wait 24 hours before making another."
        );
        setOpen(false);
      });
  };

  return (
    <div style={{ marginBottom: "88px", marginTop: "60px" }}>
      <ToastContainer />
      {showlogin === 0 || showlogin === "" ? (
        <div className="mt-40">
          Your trading account is being prepared, you will have access to it
          soon.
        </div>
      ) : (
        <Typography sx={{ marginTop: "9%", lineHeight: "3.5" }}>
          Click the button below to request for a
          <br />
          Second Chance Account
        </Typography>
      )}
      <Modal open={open} onClose={() => setOpen(false)}>
        <Box sx={style}>
          <Box sx={mainContainer}>
            <Typography>
              Notice: Please note that requesting a second chance account
              implies that you are forfeiting the first and it would be invalid.
            </Typography>
            <Typography>
              Please click proceed if you accept this terms
            </Typography>
            <Button
              variant="outlined"
              onClick={getSecondChance}
              sx={{
                marginTop: "5%",
                width: "10%",
                border: "none",
                backgroundColor: "#359602",
                color: "white",
                textTransform: "none",
                "&:hover": {
                  backgroundColor: "#359602",
                },
              }}
            >
              Proceed
            </Button>
          </Box>
        </Box>
      </Modal>
      {showlogin === 0 || showlogin === "" ? (
        <div></div>
      ) : (
        <Button
          variant="outlined"
          onClick={() => setOpen(true)}
          sx={{
            marginTop: "5%",
            width: "250px",
            border: "none",
            backgroundColor: "#359602",
            color: "white",
            textTransform: "none",
            "&:hover": {
              backgroundColor: "#359602",
            },
          }}
        >
          Request 2nd Chance Account
        </Button>
      )}
    </div>
  );
}

export default SecondChance;
