import React from "react";
import {
  Box,
  TextField,
  FormControl,
  CircularProgress,
  Button,
  Modal,
  Typography,
} from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import axios from "../axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const mainContainer = {
  justifyContent: "center",
  alignItems: "center",
};
function PaypalInfo(props) {
  const [loading, setLoading] = React.useState(false);
  const [paypalEmail, setPaypalEmail] = React.useState("");
  const [available, setAvailable] = React.useState(true);
  const [open, setOpen] = React.useState(false)

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: "bottom-center",
    });
  };


  React.useEffect(() => {
    axios.get("/update-bank-details/").then((res) => {
      console.log(res.data);
      if (res.data.paypal_address !== null) {
        setPaypalEmail(res.data.paypal_address);
        setAvailable(true)
      } else {
        setPaypalEmail("");
        setAvailable(false)
      }

    });
  }, []);
  const UpdatePersonalInformation = () => {
    setLoading(true);
    if (available) {
      setOpen(true)
    } else {
      axios
        .patch("/update-bank-details/", {
          paypal_address: paypalEmail,
        })
        .then((res) => {
          setLoading(false);
          // console.log(res);
          showRequestSuccess("Paypal information has been updated successfully.")
        }).catch(err => {
          setLoading(false);
          console.log(err);
          showRequestFailed("Failed to update paypal information.")
        })
    }

  };

  return (
    <Box
      sx={{
        width: "70%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "white",
      }}
      className="ProfileMain"
    >
      <ToastContainer />
      <Box
        sx={{
          textAlign: "center",
          fontSize: "24px !important",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          minHeight: "55px",
          backgroundColor: "white",
          borderRadius: "5px 5px 0px 0px",
          lineHeight: "60px",
          fontWeight: 494,
        }}
      >
        PayPal Information
      </Box>
      <FormControl
        sx={{
          width: "-webkit-fill-available",
          padding: "0% 7% 0% 7%",
          marginTop: "5%",
          fontSize: "16px !important",
        }}
        variant="standard"
        className="ProfileInfoDetails"
      >
        <div style={{ width: "100%" }} className="ProfileInfoDetails1">
          <label>Paypal Email*</label>
          <br />
          <br />
          <TextField
            style={{ width: "100%" }}
            InputProps={{
              readOnly: false,
            }}
            value={paypalEmail}
            sx={{
              "& .MuiInputBase-input": {
                // border: "1px solid #ced4da",

                background: "#FFFFFF",

                border: "0.5px solid rgba(0, 0, 0, 0.2)",
                boxShadow: "inset 0px 0px 2px rgba(0, 0, 0, 0.25)",
                borderRadius: "5px",
                height: "13px",
                width: "100%",
                fontSize: "16px !important",
              },
            }}
            onChange={e => setPaypalEmail(e.target.value)}
          />
        </div>
      </FormControl>


      <br />
      <br />
      <br />
      <Box
        sx={{
          textAlign: "center",
        }}
      >
        <Button
          onClick={UpdatePersonalInformation}
          variant="contained"
          sx={{
            width: "90px",
            background: "#359602",
            color: "white",
            height: "45px",
            borderRadius: "5px",
            alignSelf: "center",
            fontSize: "16px !important",
            "&:hover": {
              background: "#359602",
            },
            marginBottom: "5%",
          }}
          endIcon={
            loading && (
              <CircularProgress
                size={12}
                sx={{
                  zIndex: 1,
                  color: "white",
                }}
              />
            )
          }
        >
          Update
        </Button>
      </Box>
      <Modal open={open} onClose={() => {
        setOpen(false)
        setLoading(false)
      }}>
        <Box sx={style}>
          <Box sx={mainContainer}>
            <Typography>
              Notice: To change your paypal information, please contact support. you can find our contact information at ft9ja.com/contact.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}



export default PaypalInfo;
