import React, { useState } from "react";
import { AccContext } from "../Context/OtherContext";
import { Box, Modal, Typography } from "@mui/material";

import axios from "../axios";
import { ToastContainer, toast } from "react-toastify";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const mainContainer = {
  justifyContent: "center",
  alignItems: "center",
};

function RefPayout() {
  const [open, setOpen] = React.useState(false);
  // const [accNum, setAccNum] = React.useState("");
  // const [bankName, setBankName] = React.useState("");
  // const [firstname, setFirstname] = React.useState("");
  // const [lastname, setLastname] = React.useState("");
  const [loading, setLoading] = React.useState(false);
  const { values6 } = React.useContext(AccContext);
  // const [exists, setExists] = React.useState([]);
  // const [disable, setDisable] = React.useState(false);
  const [banked] = values6;
  // const existsReverse = exists.reverse();
  const [refresh, setRefresh] = React.useState(false);
  const [available, setAvailable] = useState();
  console.log(available);

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: "bottom-center",
    });
  };

  // React.useEffect(() => {
  //   axios.get("/update-bank-details/").then((res) => {
  //     // console.log(res.data);
  //     if (res.data.account_number !== null && res.data.bank_name !== null) {
  //       setAccNum(res.data.account_number);
  //       // setBankName(res.data.bank_name);
  //     } else {
  //       setAccNum("");
  //       // setBankName("");
  //     }
  //   });
  // }, []);

  const sendToBackend = () => {
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    axios
      .post(`refgraph/?amount=${banked}`, { device_type: deviceType })
      .then((res) => {
        // console.log(res)
        setRefresh(!refresh);
        showRequestSuccess("Referral payout request has been sent.");
        setLoading(false);
        // window.location.reload()
      })
      .catch((err) => {
        console.log(err);
        showRequestFailed("Failed to send payout request");
      });
  };

  // React.useEffect(() => {
  //   const getData = async () => {
  //     try {
  //       const res = await fetch("https://sheetdb.io/api/v1/lx1qt8sgjm0gl");
  //       const data = await res.json();
  //       // console.log(data)
  //       setExists(
  //         data.filter(
  //           (res) =>
  //             Number(res.Amount_Naira) === banked &&
  //             res.Account_Number === accNum
  //         )
  //       );
  //       setDisable(false);
  //     } catch (error) {}
  //   };
  //   getData();
  // }, [accNum, banked, refresh]);

  const handleRefPayout = () => {
    setLoading(true);
    if (banked > 0) {
      axios.get("/checkrefpayoutshow/").then((res) => {
        console.log(res.data);
        if (res.data[0] === true) {
          setAvailable(res.data[0]);
          setOpen(true);
        } else {
          sendToBackend();
        }
        // setFirstname(res.data.user.firstname);
        // setLastname(res.data.user.lastname);
      });
    } else {
      setOpen(true);
    }
  };
  return (
    <div className="flex flex-col items-center mt-10">
      <ToastContainer />
      <p className="text-center text-base">
        Click the button below to request for referral commisson payout
      </p>
      <button
        disabled={loading}
        onClick={() => handleRefPayout()}
        className="cursor-pointer text-white bg-[#359602] h-[48px] border-none text-base rounded-lg px-3"
      >
        {loading ? "Sending..." : "Referral Payout"}
      </button>
      <p>Referral Commission: #{banked}</p>
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          setLoading(false);
        }}
      >
        <Box sx={style}>
          <Box sx={mainContainer}>
            <Typography>
              {`Notice: ${
                banked === 0 && "You do not have a referral commission"
              }${available === false && banked === 0 ? "." : ""} ${
                available === true && banked === 0 ? "and" : ""
              } ${
                available === true
                  ? "You already have a pending referral payout request."
                  : ""
              }`}
            </Typography>
          </Box>
        </Box>
      </Modal>
    </div>
  );
}

export default RefPayout;
