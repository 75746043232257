/* eslint-disable no-unused-vars */
import React from "react";
import { Box, Tab, Tabs } from "@mui/material";
import DailyDD from "./DailyDD";

import DailyDDMobile from "./DailyDDMobile";
import DailyDDText from "./DailyDDText";
import TradingDays from "./TradingDays";
import TradingDaysMobile from "./TradingDaysMobile";
import TradingDaysText from "./TradingDaysText";
import SecondChance from "./SecondChance";
import ReBar from "./ReBar";
import { useMediaQuery } from "@mui/material";
import ReLine from "./ReLine";
import { AccContext } from "../Context/OtherContext";
import { useSearchParams } from "react-router-dom";
import AsoRequest from "./AsoVerificationRequest";
import TradingHistoryMobile from "./TradingHistoryMobile";
import TradingHistory from "./TradingHistory";

function MainDashGraph() {
  const [value, setValue] = React.useState(0);
  const { values, values2 } = React.useContext(AccContext);
  const [showtype] = values2;
  // eslint-disable-next-line no-unused-vars
  const [showlogin] = values;
  const mobile = useMediaQuery("(max-width:600px)");
  const [searchParams] = useSearchParams();
  const number = searchParams.get("number");
  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  console.log(showtype)
  const sx = {
    "&.Mui-selected": {
      backgroundColor: "#359602",
      color: "white",
      borderBottom: "None",
      borderLeft: "None",
    },
    minHeight: "32px",
    fontWeight: "bold",
    color: "#000",
    border: "1pt solid black",
    borderTop: "0px solid #fff",
    borderBottom: "0.5px solid rgba(0, 0, 0, 0.8)",
    borderLeft: "0.5px solid rgba(0, 0, 0, 0.8)",
    borderRight: "0.5px solid rgba(0, 0, 0, 0.8)",
  };

  function TabPanel(props) {
    const { children, value, index, ...other } = props;
    return (
      <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
      >
        {value === index && (
          <Box sx={{ textAlign: "-webkit-center" }}>{children}</Box>
        )}
      </div>
    );
  }

  function a11yProps(index) {
    return {
      id: `simple-tab-${index}`,
      "aria-controls": `simple-tabpanel-${index}`,
    };
  }

  return (
    <Box className="MainGraph">
      <Box className="Nav2">
        <Tabs
          sx={{ height: "23px" }}
          variant="scrollable"
          scrollButtons={false}
          className="TabGrp2"
          value={value}
          onChange={handleChange}
          aria-label="nav tabs example"
        >
          <Tab
            sx={sx}
            style={{ borderRight: "None" }}
            className="Tab2"
            {...a11yProps(0)}
            label="Progress"
          />
          <Tab
            sx={sx}
            style={{ borderRight: "None" }}
            className="Tab2"
            {...a11yProps(1)}
            label="Daily DD"
          />
          <Tab
            sx={sx}
            style={{ borderRight: "None" }}
            className="Tab2"
            {...a11yProps(2)}
            label="Trading Days"
            id="TD"
          />
          <Tab
            sx={sx}
            style={{ borderRight: "None" }}
            className="Tab2"
            {...a11yProps(3)}
            label="Trading History"
            id="TH"
          />
          {/* <Tab
            sx={sx}
            className="Tab2"
            {...a11yProps(3)}
            label="Consistency Rule"
          /> */}
          {number === null && (showtype?.toLowerCase() === "zuma" || showtype?.toLowerCase() === "zuma+") ? (
            <Tab
              sx={sx}
              style={{ borderRight: "None" }}
              className="Tab2"
              {...a11yProps(4)}
              label="2nd Chance"
            />
          ) : (
            <Tab
              sx={sx}
              style={{ borderRight: "None", display: "none" }}
              className="Tab2"
              {...a11yProps(4)}
              label="2nd Chance"
            />
          )}
          {number === null &&
            (showtype.toLowerCase() === "zuma" || showtype.toLowerCase() === "zuma+" || showtype.toLowerCase() === "sca+" ||
              showtype.toLowerCase() === "zuma-second-chance" ||
              showtype.toLowerCase() === "verification") && (
              <Tab
                sx={sx}
                style={{ borderRight: "None" }}
                className="Tab2"
                {...a11yProps(5)}
                label="Aso Request"
              />
            )}
          {showtype.toLowerCase() === "challenge" && number === null && (
            <Tab
              sx={sx}
              style={{ borderRight: "None" }}
              className="Tab2"
              {...a11yProps(5)}
              label="Verification Request"
            />
          )}
        </Tabs>
      </Box>
      <TabPanel value={value} index={0}>
        {number !== null && (
          <p className="font-semibold text-lg">Account Drawdown</p>
        )}
        {showlogin === 0 || showlogin === "" ? (
          <div className="mt-40">
            Your trading account is being prepared, you will have access to it
            soon.
          </div>
        ) : (
          <Box className="DashGraph">
            <Box className="BarGraph1">
              <ReBar />
            </Box>
            <Box className="LineGraph2">
              <ReLine />
            </Box>
          </Box>
        )}
      </TabPanel>
      <TabPanel value={value} index={1}>
        <br />
        <DailyDDText />
        {mobile ? <DailyDDMobile /> : <DailyDD />}
      </TabPanel>
      <TabPanel value={value} index={2}>
        <br />
        <TradingDaysText />
        {mobile ? <TradingDaysMobile /> : <TradingDays />}
      </TabPanel>
      {/* <TabPanel value={value} index={3}>
        <TradingDaysText />
        <br />
        <ConstitencyGraph />
      </TabPanel> */}
      <TabPanel value={value} index={3}>
        {mobile ? <TradingHistoryMobile /> : <TradingHistory />}
      </TabPanel>
      <TabPanel value={value} index={4}>
        <SecondChance />
      </TabPanel>
      <TabPanel value={value} index={5}>
        <AsoRequest />
      </TabPanel>
    </Box>
  );
}

export default MainDashGraph;
