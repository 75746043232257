import "./index.css";
import React from "react";
import ReactDOM from "react-dom";
import App from "./App";

// Get the root element
const rootElement = document.getElementById('root');

// Create a root and render your App
const root = ReactDOM.createRoot(rootElement);
root.render(
  <React.Fragment>
    <App />
  </React.Fragment>
);
// ReactDOM.render(
//   <React.Fragment>
//     <App />
//   </React.Fragment>,
//   document.getElementById("root")
// );