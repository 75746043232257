import React from "react";
import FeedBarGraph from "./FeedBarGraph";
import FeedLineGraph from "./FeedLineGraph";
import { Box } from "@mui/material";
import axios from "../axios";
import { GraphProvider } from "../Context/GraphContext";
import { AccContext } from "../Context/OtherContext";
import IndiDates from "./IndiDates";
import { useSearchParams } from "react-router-dom";

function GraphList(props) {
  const [info, setInfo] = React.useState([]);
  const { values, values5 } = React.useContext(AccContext);
  const [showlogin] = values;
  const [showSca] = values5;
  // const [setShowType] = values2;
  const [searchParams] = useSearchParams();
  const number = searchParams.get("number");
  console.log(number);
  let num = props.age;
  //console.log(num);

  if (num === "Current Feed") {
    num = 1;
  } else if (num === "Last 7 Days") {
    num = 7;
  } else if (num === "Last Month") {
    num = 30;
  }
  React.useEffect(() => {
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    if (showSca !== "") {
      axios
        .post(`/getfeed/?days=${num}`, {
          number: showSca,
          device_type: deviceType,
        })
        .then((res) => {
          console.log(res.data);
          setInfo(res.data);
          //console.log(num);
          //console.log(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });
    } else {
      axios
        .post(`/getfeed/?days=${num}`, {
          number: showlogin,
          device_type: deviceType,
        })
        .then((res) => {
          console.log(res.data);
          setInfo(res.data);
          //console.log(num);
          //console.log(res.data);
        })
        .catch((err) => {
          //console.log(err);
        });
    }

    return () => {};
  }, [num, showlogin, showSca]);

  return (
    <Box>
      {info?.map((feed, index) => {
        return (
          <GraphProvider value={feed} key={index}>
            <Box className="Feed">
              <IndiDates />
              {number !== null && (
                <p className="font-semibold text-center text-lg">
                  Daily Drawdown
                </p>
              )}
              <div className="FeedGraphs">
                <Box className="FeedBarGraph">
                  <FeedBarGraph />
                </Box>
                <Box
                  sx={{ marginTop: "72px", marginBottom: "72px" }}
                  className="LineGraph3"
                >
                  <FeedLineGraph />
                </Box>
              </div>
            </Box>
          </GraphProvider>
        );
      })}
    </Box>
  );
}

export default GraphList;
