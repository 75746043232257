import React from "react";
import axios from "../axios";
import { AccContext } from "../Context/OtherContext";
import {
  BarChart,
  Bar,
  Tooltip,
  LabelList,
  ResponsiveContainer,
  ReferenceLine,
  Cell,
} from "recharts";
import Loader from "../Assets/_Com1.gif";
function ReBar() {
  const [info, setInfo] = React.useState([]);
  const [neg, setNeg] = React.useState(false);
  const { values, values5 } = React.useContext(AccContext);
  const [showlogin] = values;
  const [showSca] = values5;
  // const [setShowType] = values2;
  React.useEffect(
    () => {
      let deviceType =
        window.innerWidth <= 768
          ? "Mobile"
          : window.innerWidth <= 1024
          ? "Tablet"
          : "Desktop";
      if (showSca !== "") {
        axios
          .post(`/getdrawdownloss/`, {
            number: showSca,
            device_type: deviceType,
          })
          .then((res) => {
            // console.log(res)
            if (res.data.profit < 0) {
              //  //console.log("profit is less than 0");
              setInfo([
                {
                  profit: res.data.profit * -1,
                  drawdown: res.data.drawdown,
                },
              ]);
              setNeg(true);
            } else {
              setInfo([res.data]);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      } else {
        axios
          .post(`/getdrawdownloss/`, {
            number: showlogin,
            device_type: deviceType,
          })
          .then((res) => {
            console.log(res);
            if (res.data.profit < 0) {
              //  //console.log("profit is less than 0");
              setInfo([
                {
                  profit: res.data.profit * -1,
                  drawdown: res.data.drawdown,
                },
              ]);
              setNeg(true);
            } else {
              setInfo([res.data]);
            }
          })
          .catch((err) => {
            //console.log(err);
          });
      }
    },
    // }
    [showlogin, showSca]
  );

  const BalanceCustomizedLabel = (props) => {
    //console.log(props);
    const { x, y, width, height } = props;
    return (
      <text x={x + width / 2} y={y + height + 15} textAnchor="middle">
        Profit
      </text>
    );
  };

  const DrawdownCustomizedLabel = (props) => {
    //console.log(props);
    const { x, y, width, height } = props;
    return (
      <text
        x={x + width / 2}
        y={y + height + 15}
        fill="black"
        textAnchor="middle"
      >
        DrawDown
      </text>
    );
  };

  return info.length > 0 ? (
    <ResponsiveContainer width="80%" height={224}>
      <BarChart
        data={info}
        margin={{ bottom: 25 }}
        barCategoryGap={70}
        barGap={50}
        isAnimationActiveBoolean={false}
      >
        <Tooltip
          cursor={false}
          formatter={(value, name, props) => {
            if (neg && name === "profit") {
              return [`-${value}%`, name];
            } else {
              return [`${value}%`, name];
            }
          }}
        />
        <ReferenceLine y={0} stroke="#000" />
        <Bar dataKey="profit">
          <LabelList content={<BalanceCustomizedLabel />} position="bottom" />
          {info.map((entry, index) => (
            <Cell
              key={index}
              fill={neg ? "white" : "#359602"}
              stroke={neg ? "red" : "#359602"}
            />
          ))}
        </Bar>
        <Bar dataKey="drawdown">
          <LabelList content={<DrawdownCustomizedLabel />} position="bottom" />
          {info.map((entry, index) => (
            <Cell
              key={index}
              fill={info.drawdown > 9.9 ? "#E63B3B" : "#FFCFCF"}
            />
          ))}
        </Bar>
      </BarChart>
    </ResponsiveContainer>
  ) : (
    <div className="relative flex flex-col items-center justify-center">
      <img className="w-[200px]" src={Loader} alt="" />
      <p className="absolute text-center top-[9rem] text-[1.125rem] font-semibold">
        Loading Chart
      </p>
    </div>
  );
}

export default ReBar;
