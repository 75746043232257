import React, { useEffect, useState } from "react";
import { Box, Button, Checkbox, CircularProgress } from "@mui/material";
// import { Link } from "react-router-dom";
import ClassicCarousel from "../Componets/ClassicCarousel";
import { checkboxClasses } from "@mui/material/Checkbox";
import axios from "../axios";
import { PaymentOptions } from "../Componets/Classic";
import { Helmet } from "react-helmet";
import { ProgressBar, Step } from "react-step-progress-bar";
import "react-step-progress-bar/styles.css";

// import { PaystackButton } from "react-paystack";
function Classic() {
  const [size, setSize] = React.useState("");
  const [broker, setBroker] = React.useState("");
  const [payout, setPayout] = React.useState("");
  const [clicked, setClicked] = React.useState(false);
  const [show, setShow] = React.useState(true);
  const [loading, setLoading] = React.useState(false);
  const [showPaymentOptions, setShowPaymentOptions] = React.useState(false);
  const [email, setEmail] = React.useState("");
  const token = sessionStorage.getItem("access_token");
  const [codeLoading, setCodeLoading] = React.useState(false);
  const [discountCode, setDiscountCode] = React.useState("");
  const [codeDetails, setCodeDetails] = React.useState(null);
  const [error, setError] = React.useState(null);
  // const [notUpToTen, setNotUpToTen] = React.useState();
  const [payError, setPayError] = React.useState();
  const [loadingHundredPercent, setLoadingHundredPercent] = useState(false);
  // console.log(codeDetails);
  let newsize = size * 1;
  // const publicKey = "pk_live_45cb4ea56402832c4859a716fafa44439ef2c6a5";
  //console.log(size);
  if (size === "25000") {
    //console.log("25000 omo");
    newsize = 385500;
  } else if (size === "5000") {
    //console.log("5000 omo");
    newsize = 96000;
  } else if (size === "3000") {
    //console.log("5000 omo");
    newsize = 63000;
  } else if (size === "1000") {
    newsize = 22500;
  } else {
    newsize = size;
  }

  console.log(codeDetails);
  // const componentProps = {
  //   amount: newsize * 100,
  //   email,
  //   publicKey,
  //   text: "Pay With Paystack",
  //   onSuccess: () => alert("Thanks for doing business with us!"),
  //   onClose: () =>
  //     alert("Are you sure you don't want to complete your purchase?"),
  // };

  useEffect(() => {
    if (
      showPaymentOptions === true ||
      codeDetails?.discount_percentage === 100
    ) {
      // window.scrollTo(0, document.body.scrollHeight);
      window.scrollTo({
        top: document.body.scrollHeight,
        behavior: "smooth",
      });
    }
  }, [codeDetails?.discount_percentage, showPaymentOptions]);

  const makeAccount = () => {
    //console.log(size, broker, payout);
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    setPayError("");
    setLoading(true);

    if (codeDetails && codeDetails.discount_percentage === 100) {
      setLoadingHundredPercent(true);
      let data =
        codeDetails.discount_percentage === ""
          ? {
              acc_size: size,
              broker: broker,
              payout: payout,
              typeofaccount: "zuma",
              device_type: deviceType,
            }
          : {
              acc_size: size,
              broker: broker,
              payout: payout,
              typeofaccount: "zuma",
              discount_code: codeDetails.code,
              device_type: deviceType,
            };
      axios.post("/getnewacc/", data).then((res) => {
        // console.log(res);
        setLoadingHundredPercent(false);
        // window.location.href = "https://dashboard.ft9ja.com/payment-successful";
        // showUploadSuccess(res.data.message);
        window.location.href = "http://localhost:3000/payment-successful";
        // setIsUploading(false)
        // setLoading(false);
        // setShowPaymentOptions(true);
      });
    } else {
      // if (size === "1000") {
      //   axios
      //     .get("check-acc-size-eligibility/")
      //     .then((res) => {
      //       console.log(res);
      //       if (res.data === true) {
      //         setLoading(false);
      //         setShowPaymentOptions(true);
      //         axios.post("/attempted-payment/", {size}).then(res => {
      //           console.log(res)
      //         }).catch(err => {
      //           console.log(err)
      //         })
      //       } else {
      //         axios.post("/attempted-payment/", {size}).then(res => {
      //           console.log(res)
      //         }).catch(err => {
      //           console.log(err)
      //         })
      //         setShowPaymentOptions(false);
      //         setPayError(
      //           "You cannot have more than one $1,000 Account Size. Please select another."
      //         );
      //       }
      //     })
      //     .catch((err) => {
      //       console.log(err.response.data);
      //       if (err.response.data === false) {
      //         axios.post("/attempted-payment/", {size}).then(res => {
      //           console.log(res)
      //         }).catch(err => {
      //           console.log(err)
      //         })
      //         setShowPaymentOptions(false);
      //         setPayError(
      //           "You cannot have more than one $1,000 Account Size. Please select another."
      //         );
      //       }
      //     });
      // } else {
      //   axios.post("/attempted-payment/", {size}).then(res => {
      //     console.log(res)
      //   }).catch(err => {
      //     console.log(err)
      //   })
      //   setLoading(true);
      //   setShowPaymentOptions(true);
      // }
      setLoading(false);
      setShowPaymentOptions(true);
    }

    // setShowPaymentOptions(true);
    // axios
    //   .post("/getnewacc/", {
    //     acc_size: size,
    //     broker: broker,
    //     payout: payout,
    //     typeofaccount: "zuma",
    //   })
    //   .then((res) => {
    //     console.log(res.data);
    //     setLoading(false);
    //     setShowPaymentOptions(true);
    //   });
  };

  useEffect(() => {
    if (codeDetails && codeDetails.discount_percentage === 100) {
      makeAccount();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [codeDetails]);

  const checkCode = async () => {
    setCodeLoading(true);
    try {
      axios
        .get(`discount/validity/${discountCode}/`)
        .then((res) => {
          console.log(res);
          if (res.data.error) {
            setError(res.data.error);
          } else if (
            res.data.details.account_size !== null &&
            (res.data.details.account_model === "challenge" ||
              res.data.details.account_size !== size)
          ) {
            setError(
              `This code is only valid for a $${res.data.details.account_size} Challenge account`
            );
          } else if (res.data.details.account_model === "classic") {
            setError(
              `Congratulations!!! You have gotten a discount of ${res.data.details.discount_percentage}% on a $${res.data.details.account_size} Classic account`
            );
          } else {
            setError(
              `Congratulations!!! You have gotten a discount of ${res.data.details.discount_percentage}%`
            );
          }

          if (
            res.data &&
            res.data.error === null &&
            (res.data.details.account_model === "classic" ||
              res.data.details.account_model === null) &&
            (res.data.details.account_size === size ||
              res.data.details.account_size === null)
          ) {
            const expiryDate = new Date(res.data.details.expiry_date);
            const currentDate = new Date();
            if (currentDate > expiryDate) {
              // setIsExpired(true);
              setCodeDetails({ ...res.data.details, expired: true });
              setCodeLoading(false);
            } else {
              // setIsExpired(false);
              setCodeDetails({ ...res.data.details, expired: false });
              setCodeLoading(false);
            }
          }

          // setCodeDetails(res.data)
          // setCodeLoading(false);
        })
        .catch((err) => {
          console.log(err);
          if (err.response.data) {
            setError(err.response.data.error);
            setCodeLoading(false);
          }
        });
    } catch (error) {}
  };

  const changeLoading = () => {
    setLoading(false);
  };
  React.useEffect(() => {
    if (size && broker && payout && clicked) {
      setShow(false);
    } else {
      setShow(true);
    }
    axios.get("/profile/").then((res) => {
      setEmail(res.data.user.email);
    });
  }, [broker, clicked, payout, size]);

  return (
    <>
      <Helmet>
        <title>Classic - FT9ja</title>
      </Helmet>
      <Box>
        <Box className="Landing">
          <Box
            sx={{
              paddingY: 2,
              paddingLeft: 1,
            }}
          >
            {/* {token ? (
              <Button
                className="Btn"
                sx={{
                  position: "absolute",
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href="/dashboards"
              >
                Dashboard
              </Button>
            ) : (
              <Button
                className="Btn"
                sx={{
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href="/login"
              >
                Login
              </Button>
            )} */}
            {!token ? (
              <Button
                className="Btn"
                sx={{
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href="/login"
              >
                Login
              </Button>
            ) : (
              <Button
                className="Btn"
                sx={{
                  float: "right",
                  fontSize: 18,
                  textTransform: "none",
                  background: "#359602",
                  " &:hover": {
                    backgroundColor: "#359602",
                  },
                }}
                variant="contained"
                href="/"
              >
                Go back
              </Button>
            )}
          </Box>
          <Box className="Landingheader">
            <h1>FT9ja Classic</h1>
            <p className="landp">
              Signing up takes less than a minute. Select an Account Size and
              start trading within 24 hours
            </p>
            <Button
              className="landbtn"
              sx={{
                background: "#359602",
                " &:hover": {
                  backgroundColor: "#359602",
                },
                textTransform: "none",
              }}
              variant="contained"
            >
              Let’s start
            </Button>
          </Box>
          <br />
          <br />
          <ClassicCarousel />
          <br />
          <Box>
            <Box
              sx={{
                width: "100%",
                height: "191px",
                borderTop: "1px solid #E5E5E5",
                borderBottom: "1px solid #E5E5E5",
              }}
              className="sections"
            >
              <Box sx={{ marginLeft: "7%" }}>
                <p className="classicp">(1) Choose Account Size</p>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "100%",
                  }}
                  className="classicbtns"
                >
                  {broker !== "Deriv" && (
                    <Button
                      onClick={() => {
                        //console.log("clicked");
                        setSize("1000");
                      }}
                      variant="contained"
                      sx={{
                        background: size === "1000" ? "#359602" : "#3596021a",
                        color: size === "1000" ? "#fff" : "#000",
                      }}
                    >
                      $1000
                    </Button>
                  )}
                  {broker !== "Deriv" && (
                    <Button
                      onClick={() => {
                        //console.log("clicked");
                        setSize("3000");
                      }}
                      variant="contained"
                      sx={{
                        background: size === "3000" ? "#359602" : "#3596021a",
                        color: size === "3000" ? "#fff" : "#000",
                      }}
                    >
                      $3000
                    </Button>
                  )}
                  {broker !== "Deriv" && (
                    <Button
                      onClick={() => {
                        //console.log("clicked");
                        setSize("5000");
                      }}
                      variant="contained"
                      sx={{
                        background: size === "5000" ? "#359602" : "#3596021a",
                        color: size === "5000" ? "#fff" : "#000",
                      }}
                    >
                      $5000
                    </Button>
                  )}
                  <Button
                    onClick={() => {
                      //console.log("clicked");
                      setSize("25000");
                    }}
                    variant="contained"
                    sx={{
                      background: size === "25000" ? "#359602" : "#3596021a",
                      color: size === "25000" ? "#fff" : "#000",
                    }}
                  >
                    $25,000
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "191px",
                borderTop: "1px solid #E5E5E5",
                borderBottom: "1px solid #E5E5E5",
              }}
              className="sections"
            >
              <Box sx={{ marginLeft: "7%" }}>
                <p className="classicp">(2) Choose a Broker</p>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "start",
                    width: "58%",
                  }}
                  className="classicbtns"
                >
                  <Button
                    onClick={() => {
                      //console.log("clicked");
                      setBroker("IC Markets");
                    }}
                    variant="contained"
                    sx={{
                      background:
                        broker === "IC Markets" ? "#359602" : "#3596021a",
                      color: broker === "IC Markets" ? "#fff" : "#000",
                    }}
                  >
                    IC Markets
                  </Button>
                  {/* {size === "25000" && <Button
                    onClick={() => {
                      //console.log("clicked");
                      setBroker("Deriv");
                    }}
                    variant="contained"
                    sx={{
                      background: broker === "Deriv" ? "#359602" : "#3596021a",
                      color: broker === "Deriv" ? "#fff" : "#000",
                    }}
                  >
                    Deriv
                  </Button>} */}
                  <Button
                    onClick={() => {
                      //console.log("clicked");
                      setBroker("FXTM");
                    }}
                    variant="contained"
                    sx={{
                      background: broker === "FXTM" ? "#359602" : "#3596021a",
                      color: broker === "FXTM" ? "#fff" : "#000",
                    }}
                  >
                    FXTM
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box
              sx={{
                width: "100%",
                height: "191px",
                borderTop: "1px solid #E5E5E5",
                borderBottom: "1px solid #E5E5E5",
              }}
              className="sections"
            >
              <Box sx={{ marginLeft: "7%" }}>
                <p className="classicp">(3) Payout Frequent</p>
                <br />
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "space-between",
                    width: "58%",
                  }}
                  className="classicbtns"
                >
                  <Button
                    onClick={() => {
                      //console.log("clicked");
                      setPayout("Monthly");
                    }}
                    sx={{
                      background:
                        payout === "Monthly" ? "#359602" : "#3596021a",
                      color: payout === "Monthly" ? "#fff" : "#000",
                    }}
                    variant="contained"
                  >
                    Monthly
                  </Button>
                  <Button
                    onClick={() => {
                      //console.log("clicked");
                      setPayout("Weekly");
                    }}
                    sx={{
                      background: payout === "Weekly" ? "#359602" : "#3596021a",
                      color: payout === "Weekly" ? "#fff" : "#000",
                    }}
                    variant="contained"
                  >
                    Weekly
                  </Button>
                  <Button
                    style={{
                      visibility: "hidden",
                    }}
                    variant="contained"
                  >
                    Weekly
                  </Button>
                </Box>
              </Box>
            </Box>
            <Box className="agreement flex flex-col">
              {" "}
              <p>Have discount code? Provide discount code below:</p>
              <div className="flex items-center gap-5">
                <input
                  onChange={(e) => {
                    setCodeLoading(false);
                    setError(null);
                    setCodeDetails(null);
                    setDiscountCode(e.target.value);
                  }}
                  value={discountCode}
                  type="text"
                  className="lg:w-[300px] p-[0.5rem] outline-[#359602] rounded-[5px] text-[1rem]"
                />
                <button
                  onClick={checkCode}
                  disabled={size === "" || broker === "" || payout === ""}
                  className="lg:w-[150px] p-[0.5rem] bg-[#359602] text-white disabled:bg-gray-400 disabled:text-gray-900 disabled:border-gray-400 border-[#359602] rounded-[5px] text-[1rem]"
                >
                  Check code{" "}
                  {codeLoading && (
                    <CircularProgress
                      size={12}
                      sx={{
                        zIndex: 1,
                        color: "white",
                      }}
                    />
                  )}
                </button>
              </div>
              {error && (
                <div
                  className={`mt-1 ${
                    error.toLowerCase().includes("congratulations")
                      ? "text-[#359602]"
                      : "text-red-500"
                  }`}
                >
                  {error}
                </div>
              )}
              {/* <a className={"textLink"} href="/terms" target={"_blank"}>
                rules and terms
              </a> */}
            </Box>
            {!loadingHundredPercent &&
              codeDetails?.discount_percentage !== 100 && (
                <Box className="agreement">
                  {" "}
                  <Checkbox
                    className="checkbox"
                    sx={{
                      [`&, &.${checkboxClasses.checked}`]: {
                        color: "#359602",
                      },
                    }}
                    onChange={(e) => {
                      setClicked(e.target.checked);
                    }}
                  />{" "}
                  I have read and agreed with the{" "}
                  <a className={"textLink"} href="/terms" target={"_blank"}>
                    rules and terms
                  </a>
                </Box>
              )}
            <br />
            <br />
            <br />
            {codeDetails?.discount_percentage === 100 ? (
              <div>
                <div className="flex lg:hidden items-start pl-5 pb-5">
                  <div className="bg-[#359602] h-[100px] w-[3px]"></div>
                  <div className="flex flex-col justify-between h-[100px]">
                    <div>
                      {!loadingHundredPercent ? (
                        <div className="flex flex-row-reverse gap-3 items-center bg-white">
                          <p className="my-0 text-[1.125rem] font-semibold">
                            Processing Discount
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 2048 2048"
                          >
                            <path
                              fill="#359602"
                              d="M1024 0q141 0 272 36t244 104t207 160t161 207t103 245t37 272q0 141-36 272t-104 244t-160 207t-207 161t-245 103t-272 37q-141 0-272-36t-244-104t-207-160t-161-207t-103-245t-37-272q0-141 36-272t104-244t160-207t207-161T752 37t272-37m603 685l-136-136l-659 659l-275-275l-136 136l411 411z"
                            />
                          </svg>
                          <div className="bg-[#359602] h-[3px] w-[15px]"></div>
                        </div>
                      ) : (
                        <div className="flex flex-row-reverse gap-3 items-center bg-white">
                          <p className="my-0 text-[1.125rem] font-semibold">
                            Processing Discount
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 24 24"
                          >
                            <g
                              fill="none"
                              stroke="#359602"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            >
                              <path
                                stroke-dasharray="16"
                                stroke-dashoffset="16"
                                d="M12 3c4.97 0 9 4.03 9 9"
                              >
                                <animate
                                  fill="freeze"
                                  attributeName="stroke-dashoffset"
                                  dur="0.3s"
                                  values="16;0"
                                />
                                <animateTransform
                                  attributeName="transform"
                                  dur="1.5s"
                                  repeatCount="indefinite"
                                  type="rotate"
                                  values="0 12 12;360 12 12"
                                />
                              </path>
                              <path
                                stroke-dasharray="64"
                                stroke-dashoffset="64"
                                stroke-opacity=".3"
                                d="M12 3c4.97 0 9 4.03 9 9c0 4.97 -4.03 9 -9 9c-4.97 0 -9 -4.03 -9 -9c0 -4.97 4.03 -9 9 -9Z"
                              >
                                <animate
                                  fill="freeze"
                                  attributeName="stroke-dashoffset"
                                  dur="1.2s"
                                  values="64;0"
                                />
                              </path>
                            </g>
                          </svg>
                          <div className="bg-[#359602] h-[3px] w-[15px]"></div>
                        </div>
                      )}
                    </div>
                    <div>
                      {!loadingHundredPercent ? (
                        <div className="flex flex-row-reverse gap-3 items-center bg-white">
                          <p className="my-0 text-[1.125rem] whitespace-nowrap font-semibold">
                            Going to Dashboard
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 2048 2048"
                          >
                            <path
                              fill="#359602"
                              d="M1024 0q141 0 272 36t244 104t207 160t161 207t103 245t37 272q0 141-36 272t-104 244t-160 207t-207 161t-245 103t-272 37q-141 0-272-36t-244-104t-207-160t-161-207t-103-245t-37-272q0-141 36-272t104-244t160-207t207-161T752 37t272-37m603 685l-136-136l-659 659l-275-275l-136 136l411 411z"
                            />
                          </svg>
                          <div className="bg-[#359602] h-[3px] w-[15px]"></div>
                        </div>
                      ) : (
                        <div className="flex flex-row-reverse gap-3 items-center bg-white">
                          <p className="my-0 whitespace-nowrap text-gray-400 text-[1.125rem] font-semibold">
                            Going to Dashboard
                          </p>
                          <svg
                            xmlns="http://www.w3.org/2000/svg"
                            width="28"
                            height="28"
                            viewBox="0 0 24 24"
                          >
                            <g
                              fill="none"
                              stroke="#9ca3af"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                              stroke-width="2"
                            >
                              <path
                                stroke-dasharray="16"
                                stroke-dashoffset="16"
                                d="M12 3c4.97 0 9 4.03 9 9"
                              >
                                <animate
                                  fill="freeze"
                                  attributeName="stroke-dashoffset"
                                  dur="0.3s"
                                  values="16;0"
                                />
                                <animateTransform
                                  attributeName="transform"
                                  dur="1.5s"
                                  repeatCount="indefinite"
                                  type="rotate"
                                  values="0 12 12;360 12 12"
                                />
                              </path>
                              <path
                                stroke-dasharray="64"
                                stroke-dashoffset="64"
                                stroke-opacity=".3"
                                d="M12 3c4.97 0 9 4.03 9 9c0 4.97 -4.03 9 -9 9c-4.97 0 -9 -4.03 -9 -9c0 -4.97 4.03 -9 9 -9Z"
                              >
                                <animate
                                  fill="freeze"
                                  attributeName="stroke-dashoffset"
                                  dur="1.2s"
                                  values="64;0"
                                />
                              </path>
                            </g>
                          </svg>
                          <div className="bg-[#359602] h-[3px] w-[15px]"></div>
                        </div>
                      )}
                    </div>
                  </div>
                </div>
                <div className="lg:flex items-center justify-center pb-10 hidden ">
                  <ProgressBar
                    percent={loadingHundredPercent ? 50 : 100}
                    width={"80%"}
                    filledBackground="linear-gradient(to right, #359602, #359602)"
                    height={4}
                  >
                    <Step transition="scale">
                      {({ accomplished }) => {
                        return (
                          <div>
                            {!loadingHundredPercent ? (
                              <div className="flex flex-col items-center bg-white">
                                <p className="mt-0 lg:text-[1.125rem] mb-2 font-semibold">
                                  Processing Discount
                                </p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="28"
                                  height="28"
                                  viewBox="0 0 2048 2048"
                                >
                                  <path
                                    fill="#359602"
                                    d="M1024 0q141 0 272 36t244 104t207 160t161 207t103 245t37 272q0 141-36 272t-104 244t-160 207t-207 161t-245 103t-272 37q-141 0-272-36t-244-104t-207-160t-161-207t-103-245t-37-272q0-141 36-272t104-244t160-207t207-161T752 37t272-37m603 685l-136-136l-659 659l-275-275l-136 136l411 411z"
                                  />
                                </svg>
                              </div>
                            ) : (
                              <div className="flex flex-col items-center bg-white">
                                <p className="mt-0 lg:text-[1.125rem] mb-2 font-semibold">
                                  Processing Discount
                                </p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="28"
                                  height="28"
                                  viewBox="0 0 24 24"
                                >
                                  <g
                                    fill="none"
                                    stroke="#359602"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                  >
                                    <path
                                      stroke-dasharray="16"
                                      stroke-dashoffset="16"
                                      d="M12 3c4.97 0 9 4.03 9 9"
                                    >
                                      <animate
                                        fill="freeze"
                                        attributeName="stroke-dashoffset"
                                        dur="0.3s"
                                        values="16;0"
                                      />
                                      <animateTransform
                                        attributeName="transform"
                                        dur="1.5s"
                                        repeatCount="indefinite"
                                        type="rotate"
                                        values="0 12 12;360 12 12"
                                      />
                                    </path>
                                    <path
                                      stroke-dasharray="64"
                                      stroke-dashoffset="64"
                                      stroke-opacity=".3"
                                      d="M12 3c4.97 0 9 4.03 9 9c0 4.97 -4.03 9 -9 9c-4.97 0 -9 -4.03 -9 -9c0 -4.97 4.03 -9 9 -9Z"
                                    >
                                      <animate
                                        fill="freeze"
                                        attributeName="stroke-dashoffset"
                                        dur="1.2s"
                                        values="64;0"
                                      />
                                    </path>
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Step>
                    <Step transition="scale">
                      {({ accomplished }) => {
                        return (
                          <div>
                            {!loadingHundredPercent ? (
                              <div className="flex flex-col items-center bg-white">
                                <p className="mt-0 lg:text-[1.125rem] whitespace-nowrap mb-2 font-semibold">
                                  Going to Dashboard
                                </p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="28"
                                  height="28"
                                  viewBox="0 0 2048 2048"
                                >
                                  <path
                                    fill="#359602"
                                    d="M1024 0q141 0 272 36t244 104t207 160t161 207t103 245t37 272q0 141-36 272t-104 244t-160 207t-207 161t-245 103t-272 37q-141 0-272-36t-244-104t-207-160t-161-207t-103-245t-37-272q0-141 36-272t104-244t160-207t207-161T752 37t272-37m603 685l-136-136l-659 659l-275-275l-136 136l411 411z"
                                  />
                                </svg>
                              </div>
                            ) : (
                              <div className="flex flex-col items-center bg-white">
                                <p className="mt-0 whitespace-nowrap text-gray-400 lg:text-[1.125rem] mb-2 font-semibold">
                                  Going to Dashboard
                                </p>
                                <svg
                                  xmlns="http://www.w3.org/2000/svg"
                                  width="28"
                                  height="28"
                                  viewBox="0 0 24 24"
                                >
                                  <g
                                    fill="none"
                                    stroke="#9ca3af"
                                    stroke-linecap="round"
                                    stroke-linejoin="round"
                                    stroke-width="2"
                                  >
                                    <path
                                      stroke-dasharray="16"
                                      stroke-dashoffset="16"
                                      d="M12 3c4.97 0 9 4.03 9 9"
                                    >
                                      <animate
                                        fill="freeze"
                                        attributeName="stroke-dashoffset"
                                        dur="0.3s"
                                        values="16;0"
                                      />
                                      <animateTransform
                                        attributeName="transform"
                                        dur="1.5s"
                                        repeatCount="indefinite"
                                        type="rotate"
                                        values="0 12 12;360 12 12"
                                      />
                                    </path>
                                    <path
                                      stroke-dasharray="64"
                                      stroke-dashoffset="64"
                                      stroke-opacity=".3"
                                      d="M12 3c4.97 0 9 4.03 9 9c0 4.97 -4.03 9 -9 9c-4.97 0 -9 -4.03 -9 -9c0 -4.97 4.03 -9 9 -9Z"
                                    >
                                      <animate
                                        fill="freeze"
                                        attributeName="stroke-dashoffset"
                                        dur="1.2s"
                                        values="64;0"
                                      />
                                    </path>
                                  </g>
                                </svg>
                              </div>
                            )}
                          </div>
                        );
                      }}
                    </Step>
                  </ProgressBar>
                </div>
              </div>
            ) : (
              <Box
                sx={{
                  marginLeft: "auto",
                  marginRight: "auto",
                  textAlign: "center",
                }}
              >
                <Button
                  onClick={makeAccount}
                  disabled={show}
                  className="agreementbtn"
                  sx={{
                    " &:hover": {
                      backgroundColor: "#359602",
                    },
                    marginBottom: "10%",
                  }}
                  variant="contained"
                  endIcon={
                    loading && (
                      <CircularProgress
                        size={12}
                        sx={{
                          zIndex: 1,
                          color: "white",
                        }}
                      />
                    )
                  }
                >
                  Proceed to Payment
                </Button>
                {payError && (
                  <p className="text-red-500 text-[1.2rem] px-2">{payError}</p>
                )}
                <br />
                {showPaymentOptions && (
                  <PaymentOptions
                    email={email}
                    amount={newsize * 100}
                    closeOptions={setShowPaymentOptions}
                    size={size}
                    payout={payout}
                    broker={broker}
                    changeLoading={changeLoading}
                    acctype="zuma"
                    dCode={
                      codeDetails &&
                      codeDetails.expired === false &&
                      codeDetails.is_active === true
                        ? codeDetails.code
                        : ""
                    }
                    discount={
                      codeDetails &&
                      codeDetails.expired === false &&
                      codeDetails.is_active === true
                        ? codeDetails.discount_percentage
                        : 0
                    }
                  />
                )}
              </Box>
            )}
          </Box>
        </Box>
      </Box>
    </>
  );
}

export default Classic;
//deploy to heroku
