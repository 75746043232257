import React from "react";
import { Box, Button, Modal, Typography } from "@mui/material";
import { ToastContainer, toast } from "react-toastify";

import axios from "../axios";
// import axios from "axios";

const style = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 400,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const mainContainer = {
  justifyContent: "center",
  alignItems: "center",
};
function IdentityInfo(props) {
  // const [loading, setLoading] = React.useState(false);
  const [isUploading, setIsUploading] = React.useState(false);
  const [available, setAvailable] = React.useState(false);
  const [open, setOpen] = React.useState(false);
  const [file, setFile] = React.useState(null);

  const showRequestSuccess = (message) => {
    toast.success(message, {
      position: "bottom-center",
    });
  };

  const showRequestFailed = (message) => {
    toast.error(message, {
      position: "bottom-center",
    });
  };

  React.useEffect(() => {
    axios.get("/update-bank-details/").then((res) => {
      // console.log(res);
      if (res.data.profile_img !== null) {
        if (res.data.profile_img !== "") {
          setAvailable(true);
        }
      }
    });
  }, []);

  function handleFileUpload(event) {
    setFile(event.target.files[0]);
  }

  function handleSubmit(event) {
    let deviceType =
      window.innerWidth <= 768
        ? "Mobile"
        : window.innerWidth <= 1024
        ? "Tablet"
        : "Desktop";
    event.preventDefault();
    let formdata = new FormData();
    formdata.append("profile_img", file);
    formdata.append("device_type", deviceType);
    if (file && available === false) {
      // Do something with the uploaded file, such as sending it to a server using AJAX or fetch
      // console.log("File uploaded:", file);
      setIsUploading(true);

      //--Upload file to server
      axios
        .post("/upload_id/", formdata, {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        })
        .then((res) => {
          // console.log(res);
          if (res.data.message === "File uploaded successfully") {
            showRequestSuccess("ID upload successful");
            setIsUploading(false);
          } else {
            showRequestFailed("ID upload failed");
            setIsUploading(false);
          }
        })
        .catch((error) => {
          console.error(error);
          showRequestFailed("ID upload failed");
          setIsUploading(false);
          // showUploadFailed("An error occurred while uploading the file.");
        });
      setFile(null); // Clear the file input field
    } else {
      console.log("Upload a file");
      setOpen(true);
    }
  }

  // const UpdatePersonalInformation = () => {
  //     setLoading(true);
  //     if (available) {
  //         setOpen(true)
  //     } else {
  //         axios
  //             .patch("/update-bank-details/", {
  //                 paypal_address: paypalEmail,
  //             })
  //             .then((res) => {
  //                 setLoading(false);
  //                 // console.log(res);
  //                 showRequestSuccess("Paypal information has been updated successfully.")
  //             }).catch(err => {
  //                 setLoading(false);
  //                 console.log(err);
  //                 showRequestFailed("Failed to update paypal information.")
  //             })
  //     }

  // };

  return (
    <Box
      sx={{
        width: "70%",
        marginLeft: "auto",
        marginRight: "auto",
        backgroundColor: "white",
      }}
      className="ProfileMain"
    >
      <ToastContainer />
      <Box
        sx={{
          textAlign: "center",
          fontSize: "24px !important",
          borderBottom: "1px solid rgba(0, 0, 0, 0.1)",
          minHeight: "55px",
          backgroundColor: "white",
          borderRadius: "5px 5px 0px 0px",
          lineHeight: "60px",
          fontWeight: 494,
        }}
      >
        ID Information
      </Box>
      <h1 className="text-base font-normal text-center mx-[1.5rem] mt-[1rem]">
        Please upload a valid means of identification for verification purposes.
        This could be a scanned copy or clear photo of your government-issued ID
        card, driver's license, passport, or any other official identification
        document. Make sure the uploaded file is clear and legible. Our system
        will securely process your identification to verify your account. Your
        uploaded document will be used for verification purposes only and will
        be handled in accordance with our privacy policy.
      </h1>

      {!file ? (
        <div className="flex justify-center mt-[2rem]">
          <label
            htmlFor="identify"
            className="bg-[#359602] text-white text-base rounded-[5px] px-5 py-2.5"
          >
            {isUploading ? "UPLOADING..." : "CLICK TO UPLOAD"}
          </label>
          <input
            type="file"
            id="identify"
            accept=".png, .jpeg, .jpg"
            className="hidden"
            disabled={isUploading}
            onChange={(e) => handleFileUpload(e)}
          />
        </div>
      ) : (
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            alignItems: "center",
          }}
        >
          <a
            href={URL.createObjectURL(file)}
            target="_blank"
            rel="noopener noreferrer"
          >
            <Typography
              variant="subtitle2"
              sx={{ "& a:hover": { textDecoration: "none" } }}
            >
              {file.name}
            </Typography>
          </a>
          <Button type="submit" disabled={!file} onClick={handleSubmit}>
            Submit
          </Button>
        </Box>
      )}

      <br />
      <br />
      <br />
      {/* <Box
                sx={{
                    textAlign: "center",
                }}
            >
                <Button
                    onClick={UpdatePersonalInformation}
                    variant="contained"
                    sx={{
                        width: "90px",
                        background: "#359602",
                        color: "white",
                        height: "45px",
                        borderRadius: "5px",
                        alignSelf: "center",
                        fontSize: "16px !important",
                        "&:hover": {
                            background: "#359602",
                        },
                        marginBottom: "5%",
                    }}
                    endIcon={
                        loading && (
                            <CircularProgress
                                size={12}
                                sx={{
                                    zIndex: 1,
                                    color: "white",
                                }}
                            />
                        )
                    }
                >
                    Update
                </Button>
            </Box> */}
      <Modal
        open={open}
        onClose={() => {
          setOpen(false);
          // setLoading(false)
        }}
      >
        <Box sx={style}>
          <Box sx={mainContainer}>
            <Typography>
              Notice: You have already uploaded an ID, to change your ID
              information, please contact support. you can find our contact
              information at ft9ja.com/contact.
            </Typography>
          </Box>
        </Box>
      </Modal>
    </Box>
  );
}

export default IdentityInfo;
