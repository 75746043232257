import React from "react";
import { Box, CssBaseline } from "@mui/material";
import AccountBtn from "../Componets/AccountBtn";
import MainNav from "../Componets/MainNav";
import AccountDetails from "../Componets/AccountDetails";
import FeedSection from "../Componets/FeedSection";
import Accounts from "../Componets/Accounts";
import { AccProvider } from "../Context/OtherContext";
import { useNavigate, useSearchParams } from "react-router-dom";
import { Helmet } from "react-helmet";
import axios from "../axios";
// import Cookies from 'js-cookie';
function ZumaDashboard() {
  const [searchParams] = useSearchParams();
  let navigate = useNavigate();
  const number = searchParams.get("number");
  React.useEffect(() => {
    var token = searchParams.get("token");
    var refresh = searchParams.get("refresh_token");
    if (token) {
      // Cookies.set("access_token", token)
      sessionStorage.setItem("access_token", token);
    }

    if (refresh) {
      // Cookies.set("refresh_token", refresh)
      sessionStorage.setItem("refresh_token", refresh);
    }

    if (token !== null) {
      //console.log("token is not null");
      return navigate("/dashboards");
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  React.useEffect(() => {
    axios
      .get("/getaccdetails/")
      .then((res) => {
        console.log(res);
        if (res.data.length > 0) {
          if (number) {
            navigate("/dashboards?number=" + number);
          } else {
            navigate("/dashboards");
          }
        } else {
          navigate("/");
        }
        // window.location.reload()
      })
      .catch((err) => {
        console.log(err);
        navigate("/");
      });

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // console.log("TOKEN = ", sessionStorage.getItem("access_token"))
  // console.log("REFRESHTOKEN = ", sessionStorage.getItem("refresh_token"))

  return (
    <>
      <Helmet>
        <title>Dashboard - FT9ja</title>
      </Helmet>
      <AccProvider>
        <div>
          <header>
            <MainNav />
          </header>
          <br />
          <CssBaseline />
          {number === null && <AccountBtn />}
          <br />
          <Box>
            <Box
              sx={{ display: "flex", flexWrap: "nowrap", width: "100%" }}
              className="dashmain addPadding"
            >
              <Accounts />

              <AccountDetails />
            </Box>
            <br />
            <FeedSection />
          </Box>
        </div>
      </AccProvider>
    </>
  );
}

export default ZumaDashboard;
