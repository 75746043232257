import React from "react";
import ReactPlayer from 'react-player'
import { Box } from "@mui/material";

function AffiliateHIW() {

  return (
    <Box>
      <Box
        sx={{
          textAlign: "center",
          fontSize: "28px",
          fontWeight: "bold",
        }}
        className="HIWheader"
      >
        How it Works
      </Box>
      <br />
      <br />
      <Box
        // sx={{
        //   display: "flex",
        //   justifyContent: "center",
        // }}
        className="flex flex-col items-center justify-center lg:flex-row"
      >
        <div className="lg:w-[50%] lg:flex lg:justify-end">
          <ReactPlayer url='https://www.youtube.com/watch?v=YbHo_g_Ul1I' width={350} height={175} />
        </div>
        <Box
          // sx={{
          //   marginLeft: "9px",
          //   fontWeight: 375,
          //   fontSize: "16px",
          // }}
          className="text-[16px] lg:ml-[9px] lg:w-[55%] w-[100%]"
        >
          <ol className="lg:w-[50%]">
            <li> Get your unique referral code. </li>
            <br />
            <li>
              {" "}
              Share it with people you want to refer. They should enter the code
              when then register on our website.{" "}
            </li>
            <br />
            <li>
              {" "}
              You will be paid a 15% referral commision when they purchase an
              account.{" "}
            </li>
          </ol>
        </Box>
      </Box>
    </Box>
  );
}

export default AffiliateHIW;
