import React from "react";
// import { Line } from "react-chartjs-2";
// eslint-disable-next-line no-unused-vars
import { Chart as ChartJS } from "chart.js/auto";
import { GraphContext } from "../Context/GraphContext";
// import { AccContext } from "../Context/OtherContext";
import {
  AreaChart,
  Area,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";
import { useState } from "react";
import { useEffect } from "react";


function FeedLineGraph() {
  // const { values3 } = React.useContext(AccContext);
  let values = React.useContext(GraphContext);
  // const [showsize] = values3;

  const [lowest, setLowest] = useState(0)
  const [highest, setHighest] = useState(0)
  // const [loading, setLoading] = values8
  // const [setShowType] = values2;
  // const [showsize] = values3;
  // console.log(showsize)

  useEffect(() => {
    const getLowest = (data) => {
      // console.log(data)
      const balances = data.map(item => Number(item.balance)).filter(value => !isNaN(value));
      const equities = data.map(item => Number(item.equity)).filter(value => !isNaN(value));
      // console.log(balances)
      // console.log(equities)
  
      // Find the lowest and highest balance values
      const lowestBalance = Math.min(...balances);
      const highestBalance = Math.max(...balances);
      // console.log(highestBalance)
      // console.log(lowestBalance)
  
      // Find the lowest and highest equity values
      const lowestEquity = Math.min(...equities);
      const highestEquity = Math.max(...equities);
      // console.log(lowestEquity)
      // console.log(highestEquity)
  
      if (lowestBalance < lowestEquity) {
        // console.log('Lowest Value:', Math.round(lowestBalance), ' (Balance)');
        setLowest(Math.round(lowestBalance))
      } else {
        // console.log('Lowest Value:', Math.round(lowestEquity), ' (Equity)');
        setLowest(Math.round(lowestEquity))
      }
  
      if (highestBalance > highestEquity) {
        // console.log('Highest Value:', Math.round(highestBalance), ' (Balance)');
        setHighest(Math.round(highestBalance))
      } else {
        // console.log('Highest Value:', Math.round(highestEquity), ' (Equity)');
        setHighest(highestEquity)
      }
    }

    getLowest(values)
  }, [values])

  
  const formatXAxis = (tickItem) => {
    if (tickItem) {
      // let hrs = tickItem.split(" ")[1].split(":")[0]
      // console.log(hrs)
      let hr = roundToNearestHour(new Date(tickItem.replace(/-/g, "/")));
      let hour = hr.toString().split(" ")[4].split(":")[0]
      // console.log(hour)
      return hour;
    } else {
      return "";
    }
  };
  function roundToNearestHour(date) {
    date.setMinutes(date.getMinutes() + 30);
    date.setMinutes(0, 0, 0);

    return date;
  }
  // let omo = roundToNearestHour(new Date(values[1].date));
  //  //console.log(omo.toString().split(" ")[4].split(":")[0], "omo");
  return (
    <ResponsiveContainer width={"100%"} aspect={1.7}>
      <AreaChart
        data={values}
        margin={{ top: 50, right: 40, left: 0, bottom: 20 }}
      >
        <CartesianGrid vertical={false} />
        <defs>
          <linearGradient id="colorUv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#F77E27B2" stopOpacity={0.7} />
            <stop offset="95%" stopColor="#D9D9D900" stopOpacity={0} />
          </linearGradient>
          <linearGradient id="colorPv" x1="0" y1="0" x2="0" y2="1">
            <stop offset="5%" stopColor="#1D435380" stopOpacity={0.5} />
            <stop offset="95%" stopColor="#D9D9D900" stopOpacity={0} />
          </linearGradient>
        </defs>
        <XAxis
          dataKey="date"
          tickFormatter={formatXAxis}
          angle={31}
          minTickGap={24}
          // axisLine={false}
          dx={35}
          dy={10}
          tickLine={false}
          tick={{ fontSize: 14 }}
          label={{
            value: "Time(hrs)",
            offset: -19,
            position: "insideBottomRight",
          }}
        />
        <YAxis
          type="number"
          domain={[dataMin => {
            // console.log(dataMin)
            return lowest - 10
          }, dataMax => {
            // console.log(dataMax)
            return highest + 10
          }]}
          tickLine={false}
          tickFormatter={(tickItem) => {
            if (tickItem) {
              let newtick = Math.round(tickItem);
              return newtick;
            } else {
              return 0;
            }
          }}
          tick={{ fontSize: 11 }}
          label={{
            value: "Equity/Balance",
            angle: -90,
            position: "insideLeft",
            fontSize: 15,
          }}
        />
        <Tooltip />
        {/* <Legend /> */}
        <Area
          type="monotone"
          dataKey="equity"
          stroke="#F77E27B2"
          fillOpacity={1}
          fill="url(#colorUv)"
        />
        <Area
          type="monotone"
          dataKey="balance"
          stroke="#1D435380"
          fillOpacity={1}
          fill="url(#colorPv)"
        />
      </AreaChart>
    </ResponsiveContainer>
  );
}

export default FeedLineGraph;
