import { Dialog } from '@headlessui/react'
import React, { useEffect, useRef, useState } from 'react'
import axios from '../axios'
import { AccContext } from "../Context/OtherContext";

function ConvertData(params) {
    const date = new Date(params);

    const options = {
        year: "numeric",
        month: "long",
        day: "numeric",
        hour: "2-digit",
        minute: "2-digit",
        second: "2-digit",
    };

    const formattedDate = date.toLocaleString("en-US", options);
    return formattedDate
}

function NotifModal(props) {
    let completeButtonRef = useRef(null)
    const [data, setData] = useState([])
    const [ids, setIds] = useState([])
    const [refresh, setRefresh] = useState(false)
    const { values9 } = React.useContext(AccContext)
    // eslint-disable-next-line no-unused-vars
    const [globalRefresh, setGlobalRefresh] = values9
    // console.log(ids)

    useEffect(() => {
        axios.get("/notifications/").then((res) => {
            console.log(res)
            if (res.data.length > 0) {
                setData(res.data)
                setIds(res.data.map(notif => notif.notification_id))
            } else {
                setData([])
            }

        }).catch(err => {
            console.log(err)
        });
    }, [refresh, globalRefresh])

    const markNotifsAsRead = async () => {
        axios.put("/notifications/", { id: ids }).then((res) => {
            console.log(res)
            setRefresh(!refresh)
            setGlobalRefresh(!globalRefresh)
        }).catch(err => {
            console.log(err)
        });
    }

    return (

        <Dialog
            open={props.isOpen}
            initialFocus={completeButtonRef}
            onClose={() => props.setIsOpen(false)}
            as="div"
            style={{ position: "relative", zIndex: "50" }}
        >
            {/* The backdrop, rendered as a fixed sibling to the panel container */}
            <div aria-hidden="true" style={{ position: "fixed", inset: "0", backgroundColor: 'black', opacity: "0.1" }} />

            {/* Full-screen container to center the panel */}
            <div style={{ display: "flex", alignItems: "Center", justifyContent: "Center", position: "fixed", inset: "0" }}>
                {/* The actual dialog panel  */}

                <Dialog.Panel
                    className="NotifModalz"
                >
                    <div style={{height:"100%"}}>
                        <div style={{display:"flex", flexDirection:"column", justifyContent:"space-between", paddingTop:"20px"}}>
                            <p
                                ref={completeButtonRef}
                                onClick={() => props.setIsOpen(false)}
                                // className="hidden font-poppins text-[#BE8688] text-[1.25rem] font-medium"
                                style={{display:"none"}}
                            >
                                close
                            </p>
                            <div style={{display:"flex", alignItems:"center", justifyContent:"space-between", paddingLeft:"1.12rem", paddingRight:"1.73rem"}}>
                                <p style={{fontSize:"1.125rem", fontWeight:600, color:"black"}}>Notifications</p>
                                <div style={{display:"flex", alignItems:"center", gap:"0.75rem"}}>
                                    <p onClick={markNotifsAsRead} style={{fontSize:"0.75rem", color:"black", cursor:"pointer"}}>Mark all as read</p>
                                    <svg onClick={() => props.setIsOpen(false)} xmlns="http://www.w3.org/2000/svg" width="18" height="16" viewBox="0 0 18 16" fill="none">
                                        <rect width="17.3333" height="16" rx="3" fill="#D9D9D9" fill-opacity="0.5" />
                                        <path d="M11.3337 5.33301L6.00037 10.6663" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                        <path d="M6.00037 5.33301L11.3337 10.6663" stroke="black" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" />
                                    </svg>
                                </div>
                            </div>
                            <div style={{display:"flex", gap:"1rem", flexDirection:"column", paddingLeft:"1.12rem", paddingRight:"2.56rem", marginTop:"1rem", paddingBottom:"1rem"}}>
                                {data?.map(dat => (
                                    <div key={dat.notification_id} style={{display:"flex", alignItems:"start", gap:"1rem"}}>
                                        {dat.is_read && <svg xmlns="http://www.w3.org/2000/svg" width="15" height="8" viewBox="0 0 8 8" fill="none" style={{marginTop:"20px"}}>
                                            <circle cx="4" cy="4" r="4" fill="#359602" />
                                        </svg>}
                                        <div style={!dat.is_read ? {marginLeft:"1.5rem", borderBottomWidth:"1px", borderBottomStyle:"solid", borderBottomColor:"#D9D9D9", paddingBottom:"1rem", width:"90%"} : {borderBottom:"1px", borderBottomStyle:"solid", borderBottomColor:"#D9D9D9", paddingBottom:"1rem", width:"90%"}}>
                                            <p style={{marginBottom:"0.75rem", color:"#434343", fontSize:"0.875rem"}}>{dat.message}</p>
                                            <p style={{color:"#43434380", fontSize:"0.75rem"}}>{ConvertData(dat.timestamp)}</p>
                                        </div>
                                    </div>
                                ))}
                            </div>

                        </div>
                    </div>
                </Dialog.Panel>

            </div>
        </Dialog>

    )
}

export default NotifModal